var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialogBatch",
      attrs: { title: "批量设置", visible: _vm.dialogVisible, width: "800px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-form-item", { attrs: { label: "考勤地点" } }, [
                _c("span", [_vm._v("以所在道路为准")]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 11 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "有效范围", prop: "scope" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.scope,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "scope", $$v)
                        },
                        expression: "form.scope",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "无电子围栏", value: 0 } },
                        [_vm._v("无电子围栏")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "指定范围", value: 1 } },
                        [_vm._v("指定范围")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 13 } },
            [
              _vm.form.scope == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "有效范围", prop: "meter" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { type: "text", placeholder: "请输入内容" },
                        model: {
                          value: _vm.form.meter,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "meter", $$v)
                          },
                          expression: "form.meter",
                        },
                      }),
                      _c("span", [_vm._v(" 米")]),
                      _c("p", { staticClass: "word" }, [
                        _vm._v(
                          "考勤打卡和检测的工作范围，可以输入 [ 100,2000 ]"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生效范围", prop: "effect" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.effect,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "effect", $$v)
                        },
                        expression: "form.effect",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { label: "全部道路", value: 0 } },
                        [_vm._v("全部道路")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { label: "部分道路", value: 1 } },
                        [_vm._v("部分道路")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _vm.form.effect == 1
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "selected",
                      staticStyle: { width: "700px" },
                      attrs: { label: "选择道路", prop: "parkIds" },
                    },
                    [
                      _c("el-transfer", {
                        attrs: {
                          titles: ["未选择", "已选择"],
                          filterable: "",
                          props: {
                            key: "parkId",
                            label: "parkName",
                          },
                          "filter-placeholder": "请输入道路名称",
                          data: _vm.parkList,
                        },
                        model: {
                          value: _vm.selected,
                          callback: function ($$v) {
                            _vm.selected = $$v
                          },
                          expression: "selected",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.sureButton } },
            [_vm._v("确 定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }