<!--
 * @Author: DZM
 * @Date: 2022-05-31 10:40:54
 * @LastEditors: faf
 * @LastEditTime: 2022-07-26 11:02:21
 * @Description:
-->
<template>
  <el-dialog :title="title" :visible.sync="dialogVisible" class="dialogBatch" :width="widths">
    <el-form ref="form" :inline="true" :model="form" :rules="rules" label-width="100px">
      <div class="firstStep" v-show="titleNum == 1">
        <div class="search">
          <el-form-item>
            <el-button type="primary" @click="ResetParklocation">回到当前考勤地点</el-button>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.scope" style="width: 150px">
              <el-option label="无电子围栏" :value="0">无电子围栏</el-option>
              <el-option label="指定范围" :value="1">指定范围</el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.scope == 1">
            <el-input
              type="text"
              :maxlength="4"
              v-model="form.meter"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="form.scope == 1">
            <span> 米,</span>
            <span class="word">考勤打卡和检测的工作范围，可以输入 [ 100,2000 ]</span>
          </el-form-item>
        </div>
        <div id="mapContent" class="mapContents"></div>
      </div>
      <div class="secondStep" v-show="titleNum == 2">
        <el-form-item label="地点名称">
          <el-input
            style="width: 200px"
            maxlength="20"
            v-model="form.attendName"
            type="text"
          ></el-input>
        </el-form-item>
        <p>输入名称方便员工识别打卡地点</p>
        <p>考勤地点：北京市海淀区海淀街道中关村大街中关村大厦</p>
      </div>
    </el-form>
    <div slot="footer" class="dialog-footer" v-if="titleNum == 1">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="firstStep">下一步</el-button>
    </div>
    <div slot="footer" class="dialog-footer" v-else>
      <el-button @click="secondStep">上一步</el-button>
      <el-button type="primary" @click="sureButton">完成</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      elecFenceId: "",
      center: [],
      map: null,
      titleNum: 1,
      dialogVisible: false,
      form: {
        scope: 0,
        attendName: "",
        meter: "",
      },
      rules: {
        parkIds: [
          {
            required: true,
            message: "请选择停车场",
            trigger: "blur",
          },
        ],
      },
      infoData: [],
      circle: null,
    };
  },
  watch: {
    form: {
      handler(val) {
        if (val.scope == 1 && val.meter) {
          this.initCircle(this.infoData, val);
        } else {
          if (this.circle) {
            this.map.remove(this.circle);
          }
        }
      },
      deep: true,
    },
    dialogVisible: {
      handler(val) {
        if (!val) {
          this.titleNum = 1;
        }
      },
    },
  },
  computed: {
    title() {
      if (this.titleNum == 2) {
        return `考勤地点设置（2/2）`;
      } else {
        return `考勤地点设置（1/2）`;
      }
    },
    widths() {
      if (this.titleNum == 2) {
        return "480px";
      } else {
        return "900px";
      }
    },
  },
  methods: {
    // 获取电子围栏信息
    getInfo(elecFenceId) {
      this.$axios
        .get("/acb/2.0/elecFenceSetting/queryDetail", {
          data: {
            parkId: elecFenceId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            let { longitude, latitude, attendName, attendDistance } = res.value;
            this.center = [longitude / 1000000, latitude / 1000000];
            this.form.attendName = attendName || "";
            this.form.meter = attendDistance || 0;
            this.form.scope = attendDistance > 0 ? 1 : 0;
          }
        })
        .catch((error) => {});
    },
    // 初始化弹框
    open(data) {
      this.dialogVisible = true;
      this.elecFenceId = data.parkId;
      this.infoData = data;
      this.getInfo(data.parkId);
      setTimeout(() => {
        this.initMap(data);
      }, 500);
    },
    close() {
      this.dialogVisible = false;
    },
    firstStep() {
      let reg = /^[0-9]*[1-9][0-9]*$/;
      if (this.form.scope != 0) {
        if (reg.test(this.form.meter) && this.form.meter >= 100 && this.form.meter <= 2000) {
        } else {
          this.$alert("考勤打卡和检测的工作范围，可以输入 [ 100,2000 ]", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return;
        }
      }
      this.titleNum = 2;
    },
    secondStep() {
      this.titleNum = 1;
    },
    // 确定
    sureButton() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //   } else {
      //     return false;
      //   }
      // });
      this.$axios
        .post("/acb/2.0/elecFenceSetting/update", {
          data: {
            parkId: this.elecFenceId,
            attendDistance: this.form.scope > 0 ? this.form.meter : this.form.scope,
            attendName: this.form.attendName,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$emit("searchData");
            this.dialogVisible = false;
          }
        })
        .catch((error) => {});
    },
    // 回到当前考勤点
    ResetParklocation() {
      this.map.setCenter(this.center);
    },
    // 初始化地图
    initMap(data) {
      this.map = new AMap.Map("mapContent", {
        resizeEnable: true, // 是否监控地图容器尺寸变化
        zoom: 13, // 初始化地图层级
        center: this.center, // 初始化地图中心点
      });
      let self = this;
      AMap.plugin(["AMap.ToolBar", "AMap.MapType"], function () {
        self.map.addControl(new AMap.ToolBar());
        self.map.addControl(new AMap.MapType());
      });
      this.initMarker();
      this.initCircle(data);
    },
    // 初始化marker
    initMarker() {
      let marker = new AMap.Marker({
        // icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        icon: new AMap.Icon({
          image: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
          size: new AMap.Size(20, 26), // 图标大小
          imageSize: new AMap.Size(20, 26),
        }),
        position: this.center,
        offset: new AMap.Pixel(-10, -26),
      });
      marker.setMap(this.map);
    },
    // 设置半径范围 data = 列表数据 val form数据
    setMater(data, val) {
      if (val && val.meter) {
        return val.meter;
      } else if (data && data.attendDistance) {
        return data.attendDistance;
      } else {
        return 0;
      }
    },
    // 初始化半径
    initCircle(data, val) {
      if (this.circle) {
        this.map.remove(this.circle);
      }
      this.circle = new AMap.Circle({
        center: this.center,
        radius: this.setMater(data, val), // 半径
        // borderWeight: 3,
        strokeColor: "#0089ff",
        strokeOpacity: 1,
        // strokeWeight: 6,
        fillOpacity: 0.3,
        // strokeStyle: 'dashed',
        // strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: "#0089ff",
        zIndex: 50,
      });
      this.circle.setMap(this.map);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
 .dialog-footer{
    text-align: right;
 }
 .dialogBatch >>> .el-dialog__header {
   border-bottom: 0.5px solid #ccc;
   padding: 15px 15px 15px;
   .el-dialog__title {
     color: #333333;
     font-weight: 800;
     font-size: 17px;
   }
 }

  .dialogBatch >>> .el-dialog__body {
    padding: 0;
    .amap-toolbar {
      top: 120px !important;
    }
  }

.word {
  position: absolute;
  width: 350px;
}

.secondStep {
  margin: 20px auto;
  text-align: center;
  p {
    margin: 0 auto;
    width: 248px;
    text-align: left;
    color: #bbc1c1
    line-height: 20px;
  }
}

  .search {
    background: #ffffff;
    height: 42px;
  }

 .mapContents {
   width: 100%;
   height: 500px;
   margin-bottom: 20px;
 }
</style>
