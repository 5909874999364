<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchForm"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.region')" prop="areaId">
                <a-cascader ref="cascader" @change="parkClear"></a-cascader>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Road_name')">
                <a-park-select
                  ref="parkSelect"
                  :instance="this"
                  parkTypeRefName="parkTypeSelect"
                  operationRefName="operationSelect"
                  areaRefName="cascader"
                ></a-park-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Is_it_configured')" prop="enable">
                <el-select v-model.trim="formInline.enable" size="12">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="配置" :value="1"></el-option>
                  <el-option label="未配置" :value="0"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                v-if="$route.meta.authority.button.query"
                :loading="loading"
                @click="
                  page = 1;
                  searchData();
                "
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="info" plain icon="el-icon-delete" @click="batch">
                批量设置
              </el-button>
            </div>
            <div class="col_right mbd4"></div>
          </div>
          <!-- <el-form-item>
            <el-button type="primary" icon="el-icon-delete" @click="batch"> 批量设置 </el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="70"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            align="center"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>

          <el-table-column
            :label="$t('list.operation')"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                style="padding: 0"
                size="small"
                v-if="scope"
                @click="editClick(scope.row)"
                >{{ $t("button.edit") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 批量设置 -->
      <addFence ref="addFence" @searchData="searchData"></addFence>
      <!-- 编辑 -->
      <editFence ref="editFence" @searchData="searchData"></editFence>
    </div>
  </div>
</template>

<script>
import addFence from "./add";
import editFence from "./edit.vue";
import i18n from "@/i18n";
export default {
  name: "electronicFence",
  components: {
    addFence,
    editFence,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      tableCols: [
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.Road_name"),
          width: "",
        },
        {
          prop: "attendName",
          label: this.$t("list.Attendance_Address"),
          width: "",
        },
        {
          prop: "attendDistance",
          label: this.$t("list.Effective_range"),
          width: "",
          formatter: (row, column) => {
            if (row.attendDistance > 0) {
              return row.attendDistance + "米";
            } else {
              return i18n.t("list.No_electronic_fence");
            }
          },
        },
        {
          prop: "filter",
          label: this.$t("list.Is_it_configured"),
          width: "",
          formatter: (row, column) => {
            if (row.attendDistance > 0) {
              return i18n.t("list.yes");
            } else {
              return i18n.t("list.no");
            }
          },
        },
      ],
      parkNameValue: "",
      page: 1,
      pageSize: 15,
      total: 0,
      formInline: {
        enable: "",
      },
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    // 查询
    searchData() {
      this.$axios
        .get("/acb/2.0/elecFenceSetting/queryList", {
          data: {
            areId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect
              ? this.$refs.parkSelect.getParkId()
              : "",
            enable: this.formInline.enable,
            operationId: "",
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    // 批量设置
    batch() {
      this.$refs.addFence.open();
    },
    // 编辑
    editClick(data) {
      this.$refs.editFence.open(data);
    },
    // 分页事件
    handleCurrentChange(v) {
      this.page = v;
      this.searchData();
    },
  },
  created() {
    this.searchData();
  },
  mounted() {},
  activated() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    // padding: 22px 22px 0px;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 18px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}
</style>
