<!--
 * @Author: DZM
 * @Date: 2022-05-31 10:40:54
 * @LastEditors: faf
 * @LastEditTime: 2022-06-21 15:38:57
 * @Description:
-->
<template>
  <el-dialog title="批量设置" :visible.sync="dialogVisible" class="dialogBatch" width="800px">
    <el-form ref="form" :inline="true" :model="form" :rules="rules" label-width="100px">
      <el-col :span="24">
        <el-form-item label="考勤地点">
          <span>以所在道路为准</span>
        </el-form-item>
      </el-col>
      <el-col :span="11">
        <el-form-item label="有效范围" prop="scope">
          <el-select v-model="form.scope">
            <el-option label="无电子围栏" :value="0">无电子围栏</el-option>
            <el-option label="指定范围" :value="1">指定范围</el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="13">
        <el-form-item v-if="form.scope == 1" label="有效范围" prop="meter">
          <el-input
            style="width: 200px"
            type="text"
            v-model="form.meter"
            placeholder="请输入内容"
          ></el-input>
          <span> 米</span>
          <p class="word">考勤打卡和检测的工作范围，可以输入 [ 100,2000 ]</p>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="生效范围" prop="effect">
          <el-select v-model="form.effect">
            <el-option label="全部道路" :value="0">全部道路</el-option>
            <el-option label="部分道路" :value="1">部分道路</el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item
          v-if="form.effect == 1"
          label="选择道路"
          class="selected"
          style="width: 700px"
          prop="parkIds"
        >
          <el-transfer
            :titles="['未选择', '已选择']"
            filterable
            :props="{
              key: 'parkId',
              label: 'parkName',
            }"
            filter-placeholder="请输入道路名称"
            v-model="selected"
            :data="parkList"
          >
          </el-transfer>
        </el-form-item>
      </el-col>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureButton">确 定</el-button>
      <el-button @click="dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    let parkIdsValidator = (rule, value, callback) => {
      if (this.selected.length == 0) {
        callback(new Error("请选择道路"));
      } else {
        callback();
      }
    };
    let checkBirthday = (rule, value, callback) => {
      let reg = /^[0-9]*[1-9][0-9]*$/;
      if (reg.test(this.form.meter) && this.form.meter >= 100 && this.form.meter <= 2000) {
        callback();
      } else {
        callback(new Error("考勤打卡和检测的工作范围，可以输入 [ 100,2000 ]"));
      }
    };
    return {
      selected: [],
      parkList: [],
      dialogVisible: false,
      form: {
        scope: 0,
        meter: "",
        effect: 0,
      },
      rules: {
        scope: [
          {
            required: true,
            message: "请选择有效范围",
            trigger: "blur",
          },
        ],
        meter: [
          {
            required: true,
            message: "请选择有效范围",
            trigger: "blur",
          },
          { validator: checkBirthday, trigger: ["blur", "change"] },
        ],
        effect: [
          {
            required: true,
            message: "请选择生效范围",
            trigger: "blur",
          },
        ],
        parkIds: [{ required: true, validator: parkIdsValidator, trigger: ["change", "blur"] }],
      },
    };
  },
  methods: {
    // 查询车场列表
    queryParkList() {
      this.selected = [];
      let url = "/acb/2.0/elecFenceSetting/getUnsetParks";
      this.$axios.get(url).then((res) => {
        if (res.state != 0) {
          this.$alert(res.desc);
          return;
        }
        this.parkList = res.value;
      });
    },
    open() {
      this.dialogVisible = true;
      this.queryParkList();
    },
    close() {
      this.dialogVisible = false;
    },
    // 确定
    sureButton() {
      console.log(this.selected, "-----------------");
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/elecFenceSetting/addBatch", {
              data: {
                attendDistance: this.form.scope > 0 ? this.form.meter : this.form.scope,
                parkIds: this.form.effect == 0 ? "" : this.selected.toString(),
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$emit("searchData");
                this.dialogVisible = false;
              }
            })
            .catch((error) => {});
        } else {
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.dialog-footer{
   text-align: center;
   margin-top: 300px;
}
.dialogBatch >>> .el-dialog__header {
  border-bottom: 0.5px solid #ccc;
  padding: 15px 15px 15px;
  .el-dialog__title {
    color: #333333;
    font-weight: 800;
    font-size: 17px;
  }
}
.word {
 position: absolute;
 width: 325px;
 left: -67px;
 top: 60px;
 color: #999;
}
</style>
